exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-camp-signup-tsx": () => import("./../../../src/pages/camp-signup.tsx" /* webpackChunkName: "component---src-pages-camp-signup-tsx" */),
  "component---src-pages-dj-signup-tsx": () => import("./../../../src/pages/dj-signup.tsx" /* webpackChunkName: "component---src-pages-dj-signup-tsx" */),
  "component---src-pages-events-23-04-15-catalunathon-tsx": () => import("./../../../src/pages/events/23.04.15-catalunathon.tsx" /* webpackChunkName: "component---src-pages-events-23-04-15-catalunathon-tsx" */),
  "component---src-pages-events-index-tsx": () => import("./../../../src/pages/events/index.tsx" /* webpackChunkName: "component---src-pages-events-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mailing-list-success-tsx": () => import("./../../../src/pages/mailing-list-success.tsx" /* webpackChunkName: "component---src-pages-mailing-list-success-tsx" */),
  "component---src-pages-past-events-tsx": () => import("./../../../src/pages/past-events.tsx" /* webpackChunkName: "component---src-pages-past-events-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-workshop-signup-tsx": () => import("./../../../src/pages/workshop-signup.tsx" /* webpackChunkName: "component---src-pages-workshop-signup-tsx" */)
}

